@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  font-family: "forma-djr-deck", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "forma-djr-deck", sans-serif;
}

.display {
  font-family: "forma-djr-display", sans-serif;
}

.deck {
  font-family: "forma-djr-deck", sans-serif;
}

h3 {
  font-family: "forma-djr-display";
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 10px;
}
h3:nth-child(n+2) {
  padding-top: 10px;
}

b {
  font-weight: 600;
}

i {
  margin: 0 0.2rem;
}

h4 {
  font-weight: 600;
}

/* ul {
  list-style: disc;
  margin-left: 1em;
} */

.list-square li  {
  position: relative;
  padding-left: 18px;
}

.list-square li::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  margin-left: 5px;
  width: 3px;
  height: 3px;
  background-color: #000;
}

.list-square ul li::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  margin-left: 5px;
  width: 4px;
  height: 1px;
  background-color: #000;
}

#dot path {
  user-select: none;
  cursor: pointer;
}

svg path {
  transition: fill 0.5s ease, stroke 0.5s ease, stroke-width 0.5s ease; /* Change the duration and easing function as needed */
}

svg #dot path {
  stroke: #ffffff;
  transition: fill 0.5s ease, stroke 0.5s ease, stroke-width 0.5s ease; /* Change the duration and easing function as needed */
} 

.line-height-8 {
  line-height: 0.8;
}

.line-height-1 {
  line-height: 1;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: #0D6277;
}

.rm-padding-bottom {
  padding-bottom: unset;
}

li:last-child {
  padding-bottom: 20px;
}

li li:last-child {
  padding-bottom: unset;
}

.teams-showall {
  text-decoration: underline;
}

.spark {
  position: fixed;
  top: 50px;
  right: -50px;
  
}

.spark svg {
  height: 640px;
}

@media (max-width: 768px) {
  .spark {
    position: unset;
  }

  .spark svg {
    width: 800px;
    height: 600px;
  }
}

@media (max-width: 480px) {
  .flex {
    width: 150vw;
  }
  .spark {
    top: 10px;
    right: 5px;
  }

  .spark svg {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .flex-col > :first-child {
    order: 2;
    border-right: unset;
  }
  .flex-col > :last-child {
    order: 1;
  }
}

.text-md p {
  padding-bottom: 1.25rem;
}